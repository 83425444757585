import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import styled from 'styled-components';
import readTimeEstimate from 'read-time-estimate';

import '../styles/fonts.css';
import { above } from '../styles';

import { imageUrlFor, buildImageObj } from '../utils/image-url';

import { Container, FluidImg } from '../elements';
import Layout from '../components/Layout';
import ContentBlocks from '../components/ContentBlocks';

const HeroWrapper = styled.section`
  margin-top: 50px;
  margin-bottom: 50px;

  @media ${above.md} {
    margin-top: 100px;
    padding-right: 30%;
  }

  @media ${above.lg} {
    margin-top: 150px;
  }
`;

const ContentWrapper = styled.section`
  margin-top: 50px;
  margin-bottom: 100px;

  @media ${above.md} {
    display: flex;
    align-items: flex-start;
    padding-right: 10%;
  }
`;

const Content = styled.div`
  h1,
  h2,
  h3,
  h4 {
    font-size: 3.6rem;

    &:not(:first-child) {
      margin-top: 2em;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  h3,
  h4 {
    font-size: 2.2rem;
  }

  h5,
  h6,
  p {
    font-size: 1.8rem;

    &:not(:first-child) {
      margin-top: 1em;
    }

    &:not(:last-child) {
      margin-top: 1em;
    }
  }

  a {
    color: ${props => props.theme.color.green};
    background-image: linear-gradient(
      ${props => props.theme.color.green},
      ${props => props.theme.color.green}
    );
    background-position: 0% 92%;
    background-repeat: no-repeat;
    background-size: 0% 3px;
    transition: background-size 0.3s;

    &:hover {
      background-size: 100% 3px;
    }
  }
`;

const Heading = styled.h1`
  font-weight: bold;
  font-size: 4.6rem;
  line-height: 1.25;
  margin-bottom: 0.5em;
  color: ${props => props.theme.color.blue.navy};

  @media ${above.lg} {
    font-size: 5.2rem;
  }
`;

const Details = styled.div`
  margin: 25px 0;
  margin-right: 60px;
  display: flex;
  align-items: center;
  top: 50px;
  //border-top: 1px solid ${props => props.theme.color.blue.navy};
  //border-bottom: 1px solid ${props => props.theme.color.blue.navy};
  background: ${props => props.theme.color.grey.superLight};
  padding: 30px;
  min-width: 30%;
  @media ${above.md}{
    position: sticky;
  }
  
`;

const ReadTime = styled.p`
  margin-bottom: 10px;
  font-size: 1.6rem;
  line-height: 1;
`;

const Author = styled.p`
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1;
  margin-bottom: 0;

  @media ${above.md} {
    font-size: 1.6rem;
  }
`;

const ImgWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 60px;
  height: 60px;
  background: ${props => props.theme.color.blue.navy};
`;

const Info = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;
const MainImgWrapper = styled.div`
  width: 90%;
`;
const PostPage = ({ data }) => {
  const title = get(data, 'page.title');
  const SEODesciption = get(data, 'page.seoDescription');
  const SEOKeywords = get(data, 'page.seoKeywords');
  const content = get(data, 'page._rawContentBlocks');
  const image = get(data, 'page.mainImage');
  const author = get(data, 'page.author');
  const twitterHandle = get(data, 'page.author.twitter');

  const url = `${data.siteSetting.siteUrl}/articles/${get(
    data.page,
    'slug.current',
    ''
  )}`;

  const seo = {
    title,
    seoDescription: SEODesciption || '',
    seoKeywords: SEOKeywords,
    seoTwitterHandle: twitterHandle || '@thejibe',
    image: get(image, 'asset.fluid.src', ''),
    url,
  };

  // In minute
  const [readTime, setReadTime] = useState(0);

  const contentRef = React.useCallback(node => {
    if (node !== null) {
      const { duration } = readTimeEstimate(node.innerHTML, 275, 12, 500, [
        'img',
        'Image',
      ]);

      setReadTime(duration);
    }
  }, []);

  const maxRes = image?.asset.fluid.src.split('?')[0].concat('', '?q=100&w=2000&h=800&fit=crop')

  return (
    <Layout seo={seo}>
      <Container>
        <HeroWrapper>
          <Heading dangerouslySetInnerHTML={{ __html: title }} />
        </HeroWrapper>
        <MainImgWrapper>
          <img
            src={image && maxRes}
            width={'100%'}
          />
        </MainImgWrapper>
        <ContentWrapper>
          {author && (
            <Details>
              <ImgWrapper>
                <img
                  src={author.image && author.image.asset.fluid.src}
                  width={'100%'}
                />
              </ImgWrapper>
              <Info>
                <ReadTime>{Math.ceil(readTime)} min read</ReadTime>
                {author && <Author>{author.name}</Author>}
              </Info>
            </Details>
          )}

          <Content ref={contentRef}>
            <ContentBlocks pageBlocks={content} />
          </Content>
        </ContentWrapper>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String) {
    siteSetting: sanitySiteSettings {
      siteUrl
    }
    page: sanityPost(slug: { current: { eq: $slug } }) {
      id
      title
      seoTitle
      seoDescription
      seoKeywords
      slug {
        current
      }
      _rawContentBlocks(resolveReferences: { maxDepth: 10 })
      mainImage {
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      author {
        id
        name
        twitter
        image {
          alt
          asset {
            fluid {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
export default PostPage;
